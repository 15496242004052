import { Button, Link } from '@/shared/components';
import useBoolean from '@/shared/hooks/useBoolean';
import Wallet from '../../../assets/svg/polkajs-wallet.svg';
import { useStateChainAccount } from '../../../hooks/useStateChainAccount';
import PolkadotAccountModal from '../../PolkadotAccountModal';

export const SignIn = ({ onCompleted }: { onCompleted?: () => void }): JSX.Element => {
  const { value: open, setFalse: closeModal, setTrue: openModal } = useBoolean(false);
  const { connect, hasProvider } = useStateChainAccount();

  const connectWallet = () => {
    // TODO: add tracking?
    openModal();
    connect();
  };

  return (
    <div className="flex w-[251px] flex-col items-center space-y-5">
      <div className="cf-gray-gradient flex justify-center font-aeonikMedium text-[32px]">
        Sign in
      </div>
      <div className="flex justify-center text-center text-14 leading-none text-cf-light-2">
        You need a funded State Chain account to become a broker
      </div>
      <Wallet />
      {hasProvider ? (
        <Button onClick={connectWallet}>Connect Wallet</Button>
      ) : (
        <Link href="https://polkadot.js.org/extension/" target="_blank" className="flex">
          <Button>Install PolkaJS Wallet</Button>
        </Link>
      )}
      <PolkadotAccountModal
        open={open}
        onClose={closeModal}
        onCompleted={() => {
          // TODO: add tracking?
          onCompleted?.();
          closeModal();
        }}
      />
    </div>
  );
};
