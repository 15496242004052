import { startTransition } from 'react';
import { useQueries } from '@tanstack/react-query';
import { MacScrollbar } from 'mac-scrollbar';
import { Button } from '@/shared/components';
import { type InjectedAccountWithMeta, usePolkadot } from '@/shared/hooks/usePolkadot';
import { WarningTriangleIcon } from '@/shared/icons/large';
import LogoSquareIcon from '@/shared/icons/LogoSquare';
import { accountInfoRpcKey } from '@/shared/queryKeys';
import { FLIP_SYMBOL, TokenAmount } from '@/shared/utils';
import makeRpcRequest from '@/shared/utils/rpc';
import { BrokerModal } from './BrokerModal';
import PersonHero from '../assets/svg/circular-shadow-person.svg';

const AccountRow = ({
  account,
  onClick,
  balance,
}: {
  account: InjectedAccountWithMeta;
  onClick: (account: InjectedAccountWithMeta) => void;
  balance: TokenAmount | undefined;
}) => (
  <button
    key={account.id}
    className="hover:bg-holy-radial-gray-4-30 hover:ui-checked:bg-holy-radial-gray-5 ui-checked:bg-holy-radial-gray-5 flex w-full cursor-pointer items-center rounded-md border border-transparent p-2 outline-cf-light-2 transition ease-out ui-checked:cursor-default ui-checked:border-cf-gray-5"
    type="button"
    onClick={() => {
      onClick(account);
    }}
  >
    <div className="flex flex-col space-y-1 text-left">
      <div className="flex items-center justify-between">
        <span className="text-cf-white">{account.meta.name}</span>
        {balance && (
          <div className="flex items-center">
            <LogoSquareIcon height={10} width={10} />
            &nbsp;
            <span className="font-aeonikMono text-14 text-cf-white">
              {balance?.toPreciseFormattedString()}
            </span>
            &nbsp;
            <span className="font-aeonikMono text-14 text-cf-light-2">{FLIP_SYMBOL}</span>
          </div>
        )}
      </div>
      <span className="font-aeonikMono text-14 text-cf-light-2">{account.id}</span>
    </div>
  </button>
);

export default function PolkadotAccountModal({
  open,
  onClose,
  onCompleted,
}: {
  open: boolean;
  onClose: () => void;
  onCompleted: (selectedAccount: InjectedAccountWithMeta) => void;
}) {
  const { accounts, selectAccount } = usePolkadot();

  const results = useQueries({
    queries: accounts.map((acct) => ({
      queryKey: accountInfoRpcKey(acct.id),
      queryFn: () => makeRpcRequest('account_info', acct.id),
    })),
  });

  return (
    <BrokerModal open={open} onClose={onClose}>
      <div className="bg-holy-radial-gray-3-60 relative h-[28rem] w-[32rem] rounded-md border border-cf-gray-4 backdrop-blur-[6px]">
        <div className="flex h-full flex-col space-y-12 p-5">
          <div className="flex justify-between">
            <div>
              <span className="cf-gray-gradient font-aeonikMedium text-20">Select Account</span>
            </div>
            <PersonHero className="pointer-events-none fixed right-0 top-0" />
          </div>
          <div className="flex h-full flex-col justify-between">
            <MacScrollbar skin="dark" className="max-h-[17.5rem] overflow-y-scroll">
              {accounts.map((account, index) => (
                <AccountRow
                  account={account}
                  key={account.id}
                  onClick={(acct) => {
                    startTransition(() => {
                      selectAccount(acct);
                      onCompleted(acct);
                    });
                  }}
                  balance={results[index].data?.flip_balance ?? TokenAmount.ZERO}
                />
              ))}
            </MacScrollbar>
            <div className="flex flex-row justify-between gap-6">
              <div className="flex items-center gap-2 text-14 text-cf-orange-1">
                <div>
                  <WarningTriangleIcon width="24" height="24" />
                </div>
                <div>Ledger wallets are not compatible with the Chainflip State Chain</div>
              </div>
              <Button type="secondary-standard" onClick={() => onClose()}>
                Close
              </Button>
            </div>
          </div>
        </div>
      </div>
    </BrokerModal>
  );
}
